import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Section from "../components/Section";
import Img from "../components/Img";
import Article from "../components/Article";
import Nav from "../components/Nav";
import Footnote from "../components/Footnote";
import H1 from "../components/H1";
import H2 from "../components/H2";
import Paragraph from "../components/Paragraph";
import ParagraphPull from "../components/ParagraphPull";
import TextLink from "../components/TextLink";

import libraryCatalog from "./libraryCatalog.json";

const LibraryPage = ({ data }) => (
  <Layout>
    <SEO title="Library" />
    <Nav />
    <Section>
      <Article isCentered>
        <H1>Library</H1>
        <Paragraph isLarge>
          Oak Hall’s Library specialises in matters of antiquarian interest including heraldry,
          nobility, phaleristics, and history.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Img imgSrc={data.library.childImageSharp.fluid} imgAlt="Library of Oak Hall" />
    </Section>
    <Section>
      <Article>
        <H2>An Ever Growing Collection</H2>
        <ParagraphPull orientation="RIGHT">
          <Img
            imgSrc={data.bookplate.childImageSharp.fluid}
            imgAlt="Oak Hall Bookplate"
            isTransparent
          />
          <Footnote>Oak Hall Bookplate</Footnote>
        </ParagraphPull>
        <Paragraph isLarge>
          The Oak Hall Library is hidden behind a small door under the grand staircase. Inside is a
          richly ornamented Gothic quatrefoil ceiling, with oak paneling, and floor to ceiling
          shelves filled with a unique collection of books, manuscripts, and maps.
        </Paragraph>
        <Paragraph>
          While the library contains a collection spanning a vast array of antiquarian matters
          including vexillology, phaleristics, geography, feudalism, medieval European history, the
          British aristocracy, Scottish history, Napoleonic France, land tenure, estate management,
          and more, it specialises in heraldry, genealogy, and the nobiliary sciences. The
          individual books in the collection are not of great economic value, but the collection’s
          careful curation provides a comprehensive perspective on its primary areas of focus.
        </Paragraph>
      </Article>
    </Section>
    <Section>
      <Article>
        <H2>The Heraldry &amp; Nobiliary Sciences Collection</H2>
        <Paragraph isLarge>
          The combination of titles in the Heraldry &amp; Nobiliary Sciences Collection provide the
          discerning researcher a valuable opportunity to trace armigerous families from antiquity
          to modern times. A small sampling of some of the most interesting items from the Collection’s shelves:
        </Paragraph>
        <OrderedList>
          {libraryCatalog.map(book => (
            <OrderedItem>
              <OrderBy>{book.year}</OrderBy>
              <div>
                <Paragraph>{book.title}</Paragraph>
                <Footnote>{book.author}</Footnote>
              </div>
            </OrderedItem>
          ))}
        </OrderedList>
      </Article>
      <Img
        imgSrc={data.heraldryCollection.childImageSharp.fluid}
        imgAlt="Heraldry and Nobiliary Sciences Collection"
        isWide
      />
      <Img
        imgSrc={data.heraldryCollectionCloseUp.childImageSharp.fluid}
        imgAlt="A closeup of the Heraldry and Nobiliary Sciences Collection"
        isWide
      />
    </Section>
    <Section>
      <Article>
        <ParagraphPull orientation="LEFT">
          <Img
            imgSrc={data.partialCollection.childImageSharp.fluid}
            imgAlt="Section of the Oak Hall collection"
            isTransparent
          />
          <Footnote>A section of the Oak Hall collection</Footnote>
        </ParagraphPull>
        <H2>Academic Research Program: Heraldry &amp; Nobiliary Sciences</H2>
        <Paragraph isLarge>
          Books from the heraldry, genealogy and nobiliary sciences collection at Oak Hall are
          available to qualified Academic Researchers by appointment or by loan.
        </Paragraph>
        <Paragraph>
          To obtain a full list of titles, and to apply for participation in the program, please
          inquire via email at{" "}
          <TextLink href="mailto:librarian@oakhallestate.org">librarian@oakhallestate.org</TextLink>
        </Paragraph>
      </Article>
    </Section>
  </Layout>
);

const OrderedList = styled.ol``;

const OrderedItem = styled.li`
  display: grid;
  grid-template-columns: max-content auto;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &:last-child {
    border-bottom: none;
  }
`;

const OrderBy = styled.div`
  float: left;
  margin-right: 16px;
  height: 100%;
`;

export const query = graphql`
  query {
    library: file(absolutePath: {regex: "/\\/estate\\/gallery\\/19-Library\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookplate: file(absolutePath: {regex: "/\\/library\\/oak-hall-bookplate\\.png/"}) {
      childImageSharp {
        fluid(maxWidth: 840) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heraldryCollection: file(absolutePath: {regex: "/\\/library\\/heraldry-collection\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heraldryCollectionCloseUp: file(absolutePath: {regex: "/\\/library\\/heraldry-collection-closeup\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    partialCollection: file(absolutePath: {regex: "/\\/library\\/partial-collection\\.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          originalName
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default LibraryPage;
